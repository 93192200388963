import React from 'react'
import axios from 'axios'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import FormStyler from './formStyler'

import styles from '../styles/pages/contact-form-page.module.scss'
import Reveal from 'react-reveal/Reveal'
import '../styles/animate.css'

import finchHead from '../images/15-Finches-Icon.png'
import rightArrowBlue from '../images/right-arrow-blue.png'
import rightArrowDark from '../images/right-arrow-dark.png'
import southFinch from '../images/finches/5Finch-drawing-south.png'
import quoteBox from '../images/quote-box.png'

import classNames from 'classnames/bind'
let cx = classNames.bind(styles)

const GOOGLE_FORM_ACTION_URL =
  'https://docs.google.com/forms/u/3/d/e/1FAIpQLSeU3ADJF2iOWhViB_ESgKEUTFzEpBF-a-GQHzvhFFIb2qiOPg/formResponse'
const GOOGLE_FORM_NAME_ID = 'entry.550915504'
const GOOGLE_FORM_EMAIL_ID = 'entry.1609375434'
const GOOGLE_FORM_COMPANY_ID = 'entry.360998435'
const GOOGLE_FORM_MESSAGE_ID = 'entry.764775790'

const CORS_PROXY = 'https://wbcg-cors-anywhere.herokuapp.com/'

const ContactForm = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      company: '',
      message: '',
      showForm: true,
      savedName: '',
      sendingMessage: false,
      messageSent: false,
      messageError: false,
      arrowURL: rightArrowBlue,
      isMobile: null,
    }
    this.handleFormToggle = this.handleFormToggle.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ isMobile: true })
    }
    window.onresize = () => {
      this.setState(prevState => ({
        isMobile: window.innerWidth <= 768,
      }))
    }
  }

  mouseEnterLink() {
    this.setState({ arrowURL: rightArrowDark })
  }

  mouseLeaveLink() {
    this.setState({ arrowURL: rightArrowBlue })
  }

  handleFormToggle = () => {
    this.setState(prevState => {
      const { showForm } = prevState
      return {
        showForm: !showForm,
      }
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      sendingMessage: true,
    })

    this.sendMessage()
    this.handleFormToggle()
  }

  sendMessage = () => {
    const formData = new FormData()
    formData.append(GOOGLE_FORM_NAME_ID, this.state.name)
    formData.append(GOOGLE_FORM_EMAIL_ID, this.state.email)
    formData.append(GOOGLE_FORM_COMPANY_ID, this.state.company)
    formData.append(GOOGLE_FORM_MESSAGE_ID, this.state.message)

    axios
      .post(CORS_PROXY + GOOGLE_FORM_ACTION_URL, formData)
      .then(() => {
        this.setState({
          savedName: this.state.name,
          name: '',
          email: '',
          company: '',
          message: '',
          messageSent: true,
          sendingMessage: false,
        })
      })
      .catch(() => {
        this.setState({ messageError: true, sendingMessage: false })
      })
  }

  render() {
    return (
      <div
        className={
          this.props.isFullHeight ? styles.fullPageContainer : styles.container
        }
      >
        <div className={styles.backgroundBox} />
        <div className={styles.flexContainer}>
          {!this.state.showForm && (
            <div className={styles.responseGroup}>
              <div className={styles.finchIcon}>
                <img src={southFinch} alt="finch head" />
              </div>
              <div className={styles.quoteBox}>
                <img src={quoteBox} alt="" />
              </div>
              <div className={styles.responseMessage}>
                Hi {this.state.savedName}, <br />
                Thanks for your note. We’re excited about the idea of building
                something together! Expect a response from us soon.
              </div>
              <Link className={styles.submitAnotherResponse} to="/">
                Back to home
                <img className={styles.arrow} src={rightArrowBlue} alt="" />
              </Link>
            </div>
          )}
          {this.state.showForm && (
            <div className={styles.textGroup}>
              <Reveal effect="fadeIn" duration={1500}>
                <div className={styles.finchHead}>
                  <Link to="/">
                    <img src={finchHead} alt="finch head" />
                  </Link>
                </div>
              </Reveal>
              <Reveal effect="fadeInUpShorter">
                <div className={styles.tagline}>
                  What are you looking to build?
                </div>
              </Reveal>
              <Reveal effect="fadeInUp">
                <div className={styles.bodyText}>
                  Whether you’re a startup founder, VC or other, reach out and
                  tell us what you’re up to. How can we help?
                </div>
              </Reveal>
            </div>
          )}
          <div className={styles.formContainer}>
            {this.state.showForm && (
              <FormStyler isContactForm>
                <form onSubmit={this.handleSubmit}>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="name"
                        value={this.state.name}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Your Name</label>
                    </div>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="email"
                        value={this.state.email}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Email</label>
                    </div>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="company"
                        value={this.state.company}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Company</label>
                    </div>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="message"
                        value={this.state.message}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Message</label>
                    </div>
                  </Reveal>
                  <div
                    className={styles.submitButton}
                    onMouseEnter={this.mouseEnterLink.bind(this)}
                    onMouseLeave={this.mouseLeaveLink.bind(this)}
                  >
                    <Reveal effect="fadeInLeft">
                      <input type="submit" value="Send us your note" />
                      <img
                        className={styles.arrow}
                        src={this.state.arrowURL}
                        alt=""
                      />
                    </Reveal>
                  </div>
                </form>
              </FormStyler>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm
