import React from 'react'
import styles from '../styles/components/form-styler.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const FormStyler = props => {
	const { isContactForm, children } = props
	return (
		<div className={cx('formWrapper', { contactForm: isContactForm })}>
			{children}
		</div>
	)
}

export default FormStyler
