import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import styles from '../styles/pages/contact-form-page.module.scss'
import '../styles/animate.css'

import ContactForm from '../components/contactForm'

const ContactFormPage = class extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          customTitle="Let's Build Something Awesome Together!"
          pagePath="contact-form"
        />
        <ContactForm isFullHeight />
      </Layout>
    )
  }
}

export default ContactFormPage
